import { CssBaseline, Button, Grid, Typography} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const theme = createTheme();

function App() {

  const whatsappNumber = '56962168464';

  const handleWhatsAppClick = () => {
    const whatsappUrl = `https://wa.me/${whatsappNumber}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item style={{ textAlign: 'center' }} xs={12} sm={7} md={5}>
        <Typography sx={{fontSize: '55px', fontWeight: 700, lineHeight: '50px'}}>
          Sitio en Construcción
        </Typography>
      </Grid>
      <Grid item style={{ textAlign: 'center' }} xs={12} sm={7} md={5}>
        <Typography sx={{marginTop: '20px', marginBottom: '20px', fontSize: '20px'}}>
          Estamos trabajando en nuestra página web. ¡Pronto estaremos en línea!
        </Typography>
        <Button 
          variant="contained" 
          onClick={handleWhatsAppClick} 
          startIcon={<WhatsAppIcon/>}
          sx={{backgroundColor:"#128c7e" }}
        >
          Contáctanos por WhatsApp
        </Button>
      </Grid>
    </Grid>
  </ThemeProvider>
  );
};

export default App;
